import { StrictMode } from "react";
import { createRoot } from 'react-dom/client';
import { ChakraProvider } from "@chakra-ui/react";

import { InformationContextProvider } from "./context/InformationContext";
import App from "./App";
import { AuthContextProvider } from "./context/AuthContext";

const container = document.getElementById('app');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
  <StrictMode>
    <ChakraProvider>
      <AuthContextProvider>
        <InformationContextProvider>
          <App />
        </InformationContextProvider>
      </AuthContextProvider>
    </ChakraProvider>
  </StrictMode>
);
