import { createContext } from "react";
import { useState } from "react";

// This context provide the filled FORM data to the form when user goes back to the form.!

export const AuthContext = createContext(null);

export const AuthContextProvider = ({ children }) => {

    const [isAuth, setIsAuth] = useState(false);
    const [authUser, setAuthUser] = useState({});

    return (
        <AuthContext.Provider value={{ isAuth, setIsAuth, authUser, setAuthUser }}>
            {children}
        </AuthContext.Provider>
    );
};
