import useUploadToFirestore from "./useUploadToFirestore";

import { generateBasedEntry } from "../helpers/helper";

const useFirestoreUpload = () => {
    const { createDocument } = useUploadToFirestore();

    const uploadToFirestore = async (rootFile, stemsZipFolder, stemsUnzipFolder, rootFolderName) => {
        const uploadBoth = async (refs) => {
            await createDocument(
                generateBasedEntry(rootFolderName, true, rootFile?.ext),
                [
                    ...refs
                ],
                "clickTracks",
                stemsUnzipFolder,
                true
            );
        }

        const uploadOnlyStems = async (refs) => {
            await createDocument(
                generateBasedEntry(rootFolderName, true, rootFile?.ext),
                [
                    ...refs
                ],
                "clickTracks",
                stemsUnzipFolder,
                true
            );
        }

        const uploadOnlyFile = async (refs) => {
            await createDocument(
                generateBasedEntry(rootFolderName, false, rootFile?.ext),
                [
                    ...refs
                ],
                "clickTracks",
                stemsUnzipFolder,
                false
            );
        }
        return {
            uploadBoth,
            uploadOnlyStems,
            uploadOnlyFile
        }
    }
    return { uploadToFirestore };
};

export default useFirestoreUpload;
