// After verifying the user's credentials successfully
export function onSuccessfulLogin(user) {
    // Set an authentication flag in localStorage
    localStorage.setItem('authenticated', 'true');
    // Store user-specific data if needed
    localStorage.setItem('authUser', JSON.stringify(user));
}

// To check if the user is authenticated
export function isUserAuthenticated() {
    return localStorage.getItem('authenticated') === 'true';
}

export function getAuthUser() {
    return JSON.parse(localStorage.getItem('authUser') || {});
}

// To log the user out
export function logoutUser() {
    // Clear the authentication flag and other data
    localStorage.removeItem('authenticated');
    localStorage.removeItem('userId');
}
