import { useContext, useEffect, useState } from 'react';
import {
    ChakraProvider,
    FormControl,
    FormLabel,
    Select as ChakraSelect,
    VStack,
} from '@chakra-ui/react';
import Select from 'react-select';
import { InformationContext } from '../context/InformationContext';
import { db } from '../Firebase/FireApp';
import { collection, doc, getDoc, getDocs, getFirestore, query, where } from 'firebase/firestore';
import { not } from '../helpers/helper';
import { getAuth } from 'firebase/auth';

function TagsAndGenres({ setDisabledCloseButton, options = [] }) {
    const { setUploadingFileInfo, uploadingFileInfo } = useContext(InformationContext);
    useEffect(() => {
        console.log(options, ' options...')
    })
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [tags, setTags] = useState([]);

    const handleSelectChange = (selectedOptions, action) => {
        const options = selectedOptions.map(option => {
            return option.value;
        });

        if (action.action === "remove-value" && selectedOptions?.length === 1) {
            handleDeleteSubGenre();
            handleGenra(options, true)
        } else if (action.action === 'remove-value' && selectedOptions?.length === 0) {
            handleDeleteMainGenre();
            handleGenra(options, true);

        } else {
            handleGenra(options, false)
        }
        setTags(options)
        setSelectedOptions(selectedOptions);
    };


    useEffect(() => {
        setUploadingFileInfo(prev => {
            return { ...prev, rootInfo: { ...prev.rootInfo, tags: tags } }
        });
    }, [selectedOptions]);



    const handleGenra = (options, isDeleting) => {
        if (options.length === 1 && not(isDeleting)) {
            setUploadingFileInfo(prev => {
                return { ...prev, rootInfo: { ...prev.rootInfo, mainGenre: options[0] } }
            })
        } else if (options.length > 1 && not(isDeleting)) {
            setUploadingFileInfo(prev => {
                return { ...prev, rootInfo: { ...prev.rootInfo, subGenre: options[1] } }
            })
        }
    }


    const handleDeleteMainGenre = () => {
        setUploadingFileInfo(prev => {
            return { ...prev, rootInfo: { ...prev.rootInfo, mainGenre: '' } }
        });
    }

    const handleDeleteSubGenre = () => {
        setUploadingFileInfo(prev => {
            return { ...prev, rootInfo: { ...prev.rootInfo, subGenre: '' } }
        });
    }



    useEffect(() => {
        setDisabledCloseButton(not(tags.length > 0));
    }, [tags])


    return (
        <ChakraProvider>

            <VStack >
                <FormControl style={{ width: '95%' }}>
                    <FormLabel>Select Tags</FormLabel>
                    <Select
                        width="100%"
                        isMulti
                        options={options.map((gen) => ({
                            value: gen,
                            label: gen
                        }))}
                        value={selectedOptions}
                        onChange={handleSelectChange}

                    />
                </FormControl>
            </VStack>
        </ChakraProvider>
    );
}

export default TagsAndGenres;
