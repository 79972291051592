import { useState } from "react";
import { uploadBytes, ref, getDownloadURL } from "firebase/storage";
import { functions } from "../Firebase/FireApp";
import { httpsCallable } from "firebase/functions";

const useUploadToStorage = (storage) => {
  const [uploadedRef, setUploadedRef] = useState({});

  const uploadToStorage = async (data, path, isFile) => {
    const { file, name, ext = "", metadata } = data;
    let storageRef = '';
    if (isFile) {
      storageRef = ref(storage, `${path}/${name}`);
    } else {
      storageRef = ref(storage, `${path}/${name}${ext}`);
    }
    // 'file' comes from the Blob or File API
    const uploadedFile = await uploadBytes(storageRef, file, metadata);

    // Get the download URL of the uploaded file
    const downloadURL = await getDownloadURL(uploadedFile.ref);
    return downloadURL;
    // setUploadedRef((prev) => {
    //   return { ...prev, [name]: downloadURL };
    // });
  };

  const uploadFileToSignedUrl = async (signedUrl, file) => {
    try {
      const response = await fetch(signedUrl, {
        method: 'PUT',
        body: file,
        // headers: {
        //   'Content-Type': file.type
        // }
      });
  
      if (response.ok) {
        console.log('File uploaded successfully To R2');
      } else {
        console.error('Failed to upload file');
      }

      return response;

    } catch (error) {
      console.error('Error uploading file:', error);
      return error;
    }
  };

  const uploadToR2 = async (data, path, isFile) => {
    const { file, name, ext = "", metadata } = data;
    let objectName = '';
    if (isFile) {
      objectName = `${name}`;
    } else {
      objectName = `${name}${ext}`;
    }
    // console.log(objectName)

    const uploadUrlCallable = httpsCallable(functions, 'retrieveUploadURL');
    const uploadUrl = await uploadUrlCallable({ objectName: objectName, contentType: metadata.contentType })

    // console.log(uploadUrl)
    const uploadResult = await uploadFileToSignedUrl(uploadUrl.data, file);
    return uploadResult;
  };

  const getUploadedFileRef = (name) => {
    return uploadedRef[name];
  };

  return { uploadToStorage, uploadToR2, getUploadedFileRef };
};

export default useUploadToStorage;
