

/** Does Contains Metronome file
 * @param {fileName} string
 * @returns {boolean}
 */

export const doesContainsMetronomeWith_ = (fileName) => {
    return fileName.toLowerCase().includes("metronome_");
}
