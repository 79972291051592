import { createContext } from "react";
import { useState } from "react";

// This context provide the filled FORM data to the form when user goes back to the form.!

export const InformationContext = createContext(null);

export const InformationContextProvider = ({ children }) => {
    const [uploadingFileInfo, setUploadingFileInfo] = useState({
        rootInfo: {},
        stemsInfo: []
    });
    const [isUploading, setIsUploading] = useState(null);
    const [verified, setVerified] = useState(false);


    return (
        <InformationContext.Provider value={{ uploadingFileInfo, setUploadingFileInfo, verified, setVerified, isUploading, setIsUploading }}>
            {children}
        </InformationContext.Provider>
    );
};
