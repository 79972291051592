import { useState, useContext, useEffect } from "react";
import {
    Flex,
    Heading,
    Input,
    Button,
    InputGroup,
    Stack,
    InputLeftElement,
    chakra,
    Box,
    Avatar,
    FormControl,
    InputRightElement
} from "@chakra-ui/react";
import { FaUserAlt, FaLock } from "react-icons/fa";
import { Form, Formik, Field } from "formik";
import { collection, doc, getDoc } from "firebase/firestore";
import { AuthContext } from "../context/AuthContext";
import { db } from "../Firebase/FireApp";
import { onSuccessfulLogin } from "../helpers/localStorage";
import GoogleLoginButton from "./GoogleLogin";
import { getAuth } from "firebase/auth";
const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);

const Authed = () => {
    const { setIsAuth, setAuthUser } = useContext(AuthContext);
    const auth = getAuth().currentUser;
    const [showPassword, setShowPassword] = useState(false);


    return (
        <Formik
            initialValues={{ email: '', password: '' }}
        >
            {(props) => (
                <Flex
                    flexDirection="column"
                    width="100vw"
                    height="100vh"
                    backgroundColor="gray.200"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Stack
                        flexDir="column"
                        mb="2"
                        justifyContent="center"
                        alignItems="center"

                    >
                        <Avatar bg="teal.500" mb="-3" />
                        <Heading color="teal.400">Login</Heading>
                        <Box style={{ marginTop: 30 }} minW={{ base: "90%", md: "468px" }} >
                            <Form>
                                <Stack
                                    spacing={4}
                                    p="1rem"
                                    backgroundColor="whiteAlpha.900"
                                    boxShadow="md"
                                    align="center"
                                    style={{ borderRadius: 12 }}
                                >

                                    <GoogleLoginButton />
                                </Stack>
                            </Form>
                        </Box>
                    </Stack>
                </Flex>
            )}
        </Formik>
    );
}

export default Authed;