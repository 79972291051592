import "./styles.css";
import React, { useEffect, useContext } from "react";
import { Box, Button, Container, Flex, Heading } from "@chakra-ui/react";
import { UppyComponent } from "./components/UppyComponent";

import { AuthContext } from "./context/AuthContext";

import Authed from "./components/Authed";
import { getAuthUser, isUserAuthenticated, logoutUser } from "./helpers/localStorage";
import { not } from "./helpers/helper";
import { auth } from "./Firebase/FireApp";

export default function App() {
  const { isAuth, setIsAuth, setAuthUser } = useContext(AuthContext);
  
  useEffect(() => {
    if (isUserAuthenticated()) {
      setIsAuth(true);
      setAuthUser(getAuthUser());
    }
  }, [isAuth]);

  const handleLogout = () => {
    setIsAuth(false);
    setAuthUser({});
    logoutUser();
    window.location.reload();
  }

  const renderApp = () => {
    if (isAuth) {
      return (
        <Flex
          flexDirection="column"
          width="100vw"
          height="100vh"
          backgroundColor="gray.200"
          justifyContent="center"
          alignItems="center"
        >
          <Heading color="teal.500" fontSize="3xl" mb="5">Quick Admin To Upload Folder</Heading>
          <UppyComponent />
        </Flex>)
    } else {
      return <Authed />
    }
  }

  return (
    <>
      {
        isAuth && (<Button onClick={handleLogout} bg="teal.500" color="white" _hover={{ bg: 'white', color: 'teal.500' }} style={{ position: 'absolute', bottom: 0, right: 0, marginBottom: 12, marginRight: 12 }}>Log out</Button>)
      }

      <Container centerContent >
        <Box>
          {renderApp()}
        </Box>
      </Container>
    </>
  );
}
