// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getFunctions } from 'firebase/functions';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
  apiKey: "AIzaSyA-tWUbQjG4gcBecmR4lD9MR_uZAp7qx6o",
  authDomain: "livetrackz-260c5.firebaseapp.com",
  databaseURL: "https://livetrackz-260c5.firebaseio.com",
  projectId: "livetrackz-260c5",
  storageBucket: "livetrackz-260c5.appspot.com",
  messagingSenderId: "361281863118",
  appId: "1:361281863118:web:529602781174386891bf9f",
  measurementId: "G-6L8CKJL7MN"
};
// const firebaseConfig = {
//   apiKey: "AIzaSyDI6BdNwxJ2pOaXX_oEHsaTqjRDIRFglYA",
//   authDomain: "livetrackz-dev-6dd4b.firebaseapp.com",
//   databaseURL: "https://livetrackz-dev-6dd4b.firebaseio.com",
//   projectId: "livetrackz-dev",
//   storageBucket: "livetrackz-dev.appspot.com",
//   messagingSenderId: "751300242437",
//   appId: "1:751300242437:web:e84395b2e5668d2478806c",
//   measurementId: "G-8BS1M8P8SP"
// };


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const storage = getStorage(app);

export const auth = getAuth(app);
export const db = getFirestore(app);
export const functions = getFunctions(app);

export default app;
