import JSZip from "jszip";
import { getFileExtension } from "../helpers/helper";
import { doesContainsMetronomeWith_ } from "./helpers/useFileHandlingHelper";

const useFileHandling = () => {
    const extractAndRezipStemsFolder = async (zipData) => {
        const zip = new JSZip();
        const archive = await zip.loadAsync(zipData);
        const filteredZip = new JSZip();

        let rootFile = null;
        let stemsUnzipFolder = null;
        let stemsZipFolder = null;

        archive.forEach((relativePath, file) => {
            if (file.name.match(/\.(mp3|wav|m4a)$/i)) {
                filteredZip.file(file.name, file.async('arraybuffer'));
            }
        });
        const rootFolderName = zipData?.name?.split(".zip")[0];

        let stemsFolder = null;
        let containsRootFile = null;
        filteredZip.forEach(async (relativePath, zipEntry) => {
            if (zipEntry.dir && zipEntry.name.includes("stems")) {
                stemsFolder = zipEntry;
            } else if (
                !zipEntry.dir &&
                !zipEntry.name.includes("stems") &&
                (zipEntry.name.endsWith(".mp3") || zipEntry.name.endsWith(".wav") || zipEntry.name.endsWith(".m4a"))
            ) {
                containsRootFile = true;
                const name = zipEntry.name.split("/")[1];
                const mp3Data = await zipEntry.async("arraybuffer");
                rootFile = {
                    file: mp3Data,
                    name,
                    ext: getFileExtension(name),
                    metadata: { contentType: "audio/mpeg" }
                };
            }
        });
        if (stemsFolder) {
            const stemsZip = new JSZip();
            filteredZip
                .folder(stemsFolder.name)
                .forEach((subRelativePath, subZipEntry) => {
                    if (!subZipEntry.dir) {
                        const originalFileName = subRelativePath;
                        let newName = originalFileName; // Initialize newName with the original filename

                        // Check if the filename contains "Metronome" and an underscore
                        if (doesContainsMetronomeWith_(originalFileName)) {
                            const parts = originalFileName.split("_");
                            const metronomeExt = getFileExtension(originalFileName);
                            newName = parts[0] + metronomeExt;
                        }

                        const fileData = subZipEntry.async("uint8array");
                        stemsZip.file(newName, fileData); // Add the file with the modified name
                    }
                });


            const compressionOptions = {
                level: 9, // Adjust this value from 1 to 9 as needed
            };
            const stemsZipData = await stemsZip.generateAsync({ type: "blob", compression: 'DEFLATE', compressionOptions });
            const res = await stemsZip.loadAsync(stemsZipData);
            stemsUnzipFolder = res.files;
            stemsZipFolder = {
                file: stemsZipData,
                name: rootFolderName,
                ext: ".zip",
                metadata: {
                    contentType: "application/zip"
                }
            }
            return { rootFile, stemsZipFolder, stemsUnzipFolder, rootFolderName }
        }

        return null; // Either stems folder or specific file not found
    };

    return { extractAndRezipStemsFolder };
};

export default useFileHandling;
