import {
  doc,
  setDoc,
  collection,
  serverTimestamp,
} from "firebase/firestore";
import { getStemFilesInfo } from "../helpers/helper";

import { auth, db } from "../Firebase/FireApp";
import { useContext, useEffect, useState } from "react";
import { InformationContext } from "../context/InformationContext";
import { AuthContext } from "../context/AuthContext";
import { getAuth } from "firebase/auth";

const useUploadToFirestore = () => {
  const uploadToFirestore = (file, name) => { };
  const { uploadingFileInfo, setUploadingFileInfo, verified, setIsUploading } =
    useContext(InformationContext);
  const { authUser } = useContext(AuthContext);
  const [doesContainStems, setDoesContainStems] = useState(false);
  const currentUser = auth.currentUser;

  const createDocument = async (
    documentInfo,
    storageFilesRefs = [],
    path = "clickTracks",
    stemsFolder,
    containsStems = false
  ) => {
    setDoesContainStems(containsStems);
    documentInfo = {
      ...documentInfo,
      fileRefs: storageFilesRefs, // Concatenate arrays,
      createdBy: currentUser?.displayName || 'AdminScript',
    };
    console.log(authUser)
    if (containsStems) {
      const stemsInfo = getStemFilesInfo(stemsFolder);
      const stemsFormatedInfo = [];
      for (const stemInfo of stemsInfo) {
        stemsFormatedInfo.push(stemInfo);
      }
      setUploadingFileInfo(perv => {
        return { ...perv, rootInfo: documentInfo, stemsInfo: stemsFormatedInfo }
      });
    } else {
      setUploadingFileInfo(perv => {
        return { rootInfo: documentInfo }
      });
    }
    setIsUploading(false);
  };

  const uploadRootInfo = async (path = 'clickTracks') => {
    try {
      const trackCollectionRef = collection(db, path);
      const newTrackDocRef = doc(trackCollectionRef);
      await setDoc(newTrackDocRef, { ...uploadingFileInfo?.rootInfo, dateCreated: serverTimestamp() });
      return newTrackDocRef;
      console.log("Document created successfully!");
    } catch (error) {
      console.error("Error creating document:", error);
    }
  }

  const uploadStemsInfo = async (ref) => {
    const tracksCollectionRef = collection(ref, 'tracks');
    for (const stemInfo of [...uploadingFileInfo?.stemsInfo]) {
      const newTrackDocRef = doc(tracksCollectionRef); // Firestore will generate a unique ID
      await setDoc(newTrackDocRef, stemInfo);
    }
  }

  const push = async () => {
    const subCollectionRef = await uploadRootInfo('clickTracks');
    if (doesContainStems) {
      await uploadStemsInfo(subCollectionRef);
      window.location.reload();
    } else {
      window.location.reload();
    }
  }

  useEffect(() => {
    if (verified) {
      push();
    }
  }, [verified])

  return { uploadToFirestore, createDocument };
};

export default useUploadToFirestore;
