import { async } from "@firebase/util";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { auth, db } from "../Firebase/FireApp";
import { onSuccessfulLogin } from "../helpers/localStorage";
import LoginButton from "./google_login/LoginButton";
const GoogleLoginButton = () => {

    const { setIsAuth, setAuthUser } = useContext(AuthContext);
    const currentUser = auth.currentUser

    const handleVerifyIsAdmin = async (email) => {
        const usersCollectionRef = collection(db, 'allowedUsers');

        // Query the collection for the document with the matching email
        const querySnapshot = await getDocs(query(usersCollectionRef, where('email', '==', email)));
        if (!querySnapshot.empty) {
            // If a document with the matching email exists, you can perform your authentication logic here.
            const currentUser = querySnapshot.docs[0].data(); // Assuming you store user data in the document.
            setIsAuth(true);
            setAuthUser(currentUser);
            onSuccessfulLogin(currentUser);
        } else {
            // If no document with the matching email is found, you can handle the case accordingly.
            auth.signOut().then(() => {
                alert('You have to be admin');
            });
        }
    };


    const handleGoogleSignIn = async () => {
        const provider = new GoogleAuthProvider();
        try {
            const result = await signInWithPopup(auth, provider);
            handleVerifyIsAdmin(result?.user?.email);
            console.log(result.user);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <LoginButton handleGoogleSignIn={handleGoogleSignIn} />
        </>
    );
};

export default GoogleLoginButton;