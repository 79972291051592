export const not = (value) => !value;

const getBPM = (folderName = "") => {
  const regex = /_(\d+)BPM_/;
  const match = folderName.match(regex);

  if (match && match[1]) {
    const bpm = parseInt(match[1]);
    return bpm;
  } else {
    return null;
  }
};

const getTrachName = (folderName = "") => {
  return folderName;
};

const getTrackType = (containsStems) => {
  return containsStems ? ".presetMultiTrack" : ".presetTrack";
};

const fileExt = (containsStems, rootFileExt) => {
  
  // return containsStems ? ".multi" : rootFileExt;
  return rootFileExt;
};

const getTracklength = (folderName = "") => {
  const regex = /_(\d+)B_/;

  const match = folderName.match(regex);

  if (match) {
    const trackLength = match[1];
    return parseInt(trackLength);
  } else {
    return null;
  }
};

const getTimeSignature = (inputString = "") => {
  const regex = /_(\d+)P(\d+)/;
  const match = inputString.match(regex);

  if (match && match[1] && match[2]) {
    const modifiedString = `${match[1]}/${match[2]}`;
    return modifiedString;
  } else {
    return "NA";
  }
};


function formatDateToyyyyMMdd(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return parseInt(`${year}${month}${day}`);
}




const extractArtist = (folderName = "") => {
  const regex = /\((.*?)\)/;
  const match = folderName.match(regex);

  if (match && match[1]) {
    const artist = match[1].trim();
    if (artist === "") {
      return "NA";
    } else {
      return artist;
    }
  } else {
    return "NA";
  }
};

const getDisplayName = (folderName) => {
  return folderName.split("_")[0];
};
const getFileDisplayName = (fileName) => {
  const displayName = fileName.replace(/^\d+_|\.wav$|\.m4a$/g, "");
  return displayName;
};

const getFilePosition = (fileName) => {
  const positionMatch = fileName.match(/^\d+/); // Match digits at the start of the string
  if (positionMatch) {
    const position = parseInt(positionMatch[0]);
    return position;
  } else {
    return 0;
  }
};

const getFileName = (fileName) => {
  return fileName.split('.')[0];
}

const isMuted = (fileName) => {
  return (
    fileName.includes("metronome") ||
    fileName.includes("Metronome") ||
    fileName.includes("METRONOME")
  );
};

export const getFileExtension = (fileName) => {
  const parts = fileName.split(".");
  if (parts.length > 1) {
    return '.' + parts.pop();
  }
  return ""; // No extension found
};

// Get stems files in the form of object, and return an array for containing information for firestore.
export const getStemFilesInfo = (stems) => {
  let fileName = "";
  return Object.keys(stems).map((key) => {
    fileName = stems[key]?.name;
    return {
      tracklistPosition: getFilePosition(fileName),
      displayName: getFileDisplayName(fileName),
      muted: isMuted(fileName),
      fileEXT: getFileExtension(fileName),
      fileName: getFileName(fileName),
      volume: 1,
    };
  });
};

export const generateBasedEntry = (foldername, containsStems, rootFileExt) => {
  return {
    defaultTempo: getBPM(foldername),
    displayName: getDisplayName(foldername),
    fileEXT: fileExt(containsStems, rootFileExt),
    internalDate: formatDateToyyyyMMdd(new Date()),
    timeSignature: getTimeSignature(foldername),
    trackLength: getTracklength(foldername),
    trackName: getTrachName(foldername),
    trackType: getTrackType(containsStems),
    artist: extractArtist(foldername),
  };
};
