import { useMemo } from "react";

import Uppy from "@uppy/core";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";


const useUppyInstance = () => {
  return useMemo(() => {
    return new Uppy({
      restrictions: { maxNumberOfFiles: 1, allowedFileTypes: [".zip", ".rar"] },
      autoProceed: false,
    });
  }, []);
};

export default useUppyInstance;
