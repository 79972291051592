import React, { useEffect, useState } from "react";
import { Dashboard } from "@uppy/react";
import DragDrop from "@uppy/drag-drop";

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import useUppyInstance from "../hooks/useUppyInstance";
import useFileHandling from "../hooks/useFileHandling";
import useFirestoreUpload from "../hooks/useFirestoreUpload";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Input,
  HStack,
  Tag,
  TagLabel,
  TagCloseButton,
  useToast,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialog
} from '@chakra-ui/react'

import { useContext } from "react";
import { InformationContext } from "../context/InformationContext";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark as theme } from 'react-syntax-highlighter/dist/esm/styles/prism';

import { not } from "../helpers/helper";
import { FaWindows } from "react-icons/fa";
import TagsAndGenras from "./TagsAndGenras";
import { db, storage } from "../Firebase/FireApp";
import useUploadToStorage from "../hooks/useUploadToStorage";
import { collection, doc, getDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { logoutUser } from "../helpers/localStorage";

export const UppyComponent = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const uppy = useUppyInstance();
  const { extractAndRezipStemsFolder } = useFileHandling();
  const { uploadToStorage, uploadToR2, getUploadedFileRef } = useUploadToStorage(storage);
  const { uploadToFirestore } = useFirestoreUpload();

  const { uploadingFileInfo, setUploadingFileInfo, setVerified, isUploading, setIsUploading } = useContext(InformationContext);
  useEffect(() => {
    console.log(isUploading, 'isUploading...')
  })
  const [tags, setTags] = useState([]);
  const [value, setValue] = useState('');

  const handleUppyComplete = async (result) => {
    // Handle Uppy completion...
    const file = result.successful[0]?.data
    setIsUploading(true);
    const { rootFile, stemsZipFolder, stemsUnzipFolder, rootFolderName } = await extractAndRezipStemsFolder(file);
    const {
      uploadBoth,
      uploadOnlyStems,
      uploadOnlyFile
    } = await uploadToFirestore(rootFile, stemsZipFolder, stemsUnzipFolder, rootFolderName);
    if (stemsZipFolder && rootFile) {
      const stemsDownloadURL = await uploadToStorage(stemsZipFolder, "/clickTracks", false);
      const rootFileDownloadURL = await uploadToStorage(rootFile, '/clickTracks', true);
      const stemsR2DownloadURL = await uploadToR2(stemsZipFolder, "/clickTracks", false);
      const rootFileR2DownloadURL = await uploadToR2(rootFile, '/clickTracks', true);
      await uploadBoth([stemsDownloadURL, rootFileDownloadURL]);
    } else if (stemsZipFolder && not(rootFile)) {
      const stemsDownloadURL = await uploadToStorage(stemsZipFolder, "/clickTracks", false);
      const stemsR2DownloadURL = await uploadToR2(stemsZipFolder, "/clickTracks", false);
      await uploadOnlyStems([stemsDownloadURL]);
    } else if (not(stemsUnzipFolder) && rootFile) {
      const rootFileDownloadURL = await uploadToStorage(rootFile, '/clickTracks'.true);
      const rootFileR2DownloadURL = await uploadToR2(rootFile, '/clickTracks', true);
      await uploadOnlyFile([rootFileDownloadURL]);
    }
  };

  const handleUppyClose = () => {
    setIsUploading(true);
  }


  useEffect(() => {
    uppy.on("complete", handleUppyComplete);
    return () => uppy.off("complete", handleUppyClose);
  }, [uppy]);

  const handleDeleteTag = (index) => {
    setTags(prev => {
      return prev.filter((tag, i) => {
        return i !== index;
      })
    })
  }

  useEffect(() => {
    if (tags.length) {
      setUploadingFileInfo(prev => {
        return { ...prev, rootInfo: { ...prev.rootInfo, tags: [...tags] } }
      })
    }
  }, [tags]);

  useEffect(() => {
    if (isUploading === false) {
      onOpen();
    }
  }, [isUploading]);

  const handleDone = () => {
    setVerified(true);
    onClose();
    toast({
      position: 'top-right',
      title: 'Uploaded.',
      description: "File successfully uploaded to Firebase.",
      status: 'success',
      duration: 5000,
      isClosable: true,
    })
  }

  const [options, setOptions] = useState([]);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [disabledCloseButton, setDisabledCloseButton] = useState(true);

  function fetchData() {
    const auth = getAuth();
    const docRef = doc(db, "admin", "clickTrackArrayDefaults");
    getDoc(docRef)
      .then((docSnap) => {
        if (docSnap.exists()) {
          const genreArray = docSnap.data().genre;

          const itemsToExclude = ['Favorites', 'New Loops', 'Multi'];

          const filteredGenreArray = genreArray.filter((item) => !itemsToExclude.includes(item));

          setOptions(filteredGenreArray);
        } else {
          console.log('Document does not exist');
        }
      })
      .catch((error) => {
        auth.signOut().then(() => {
          logoutUser();
          alert('Login Again.');
          window.location.reload('/');
        });
        console.error('Error getting document:', error);
      });
  }
  useEffect(() => {
    fetchData();
  }, []);


  const handleClose = () => {
    setOpenConfirmModal(true);
  }

  return (
    <div>
      <Modal size="2xl" isOpen={isOpen} onClose={handleClose} isCentered={true} style={{ marginTop: '20px' }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Modal Title</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <h4 style={{ textAlign: 'center' }}>Verify the content before uploading to Firestore.!!!</h4>

            <TagsAndGenras setDisabledCloseButton={setDisabledCloseButton} options={options} />
            {
              openConfirmModal && (<AlertDialogPopUp setOpenConfirmModal={setOpenConfirmModal} closeModal={onClose} />)
            }
            <HStack spacing={4} marginBottom="8px">
              {tags.map((tag, index) => (
                <Tag
                  size="md"
                  key={index}
                  borderRadius='full'
                  variant='solid'
                  colorScheme='green'
                >
                  <TagLabel>{tag}</TagLabel>
                  <TagCloseButton onClick={() => handleDeleteTag(index)} />
                </Tag>
              ))}
            </HStack>

            <div style={{ height: '550px', overflowY: 'scroll' }}>
              <SyntaxHighlighter language="json" style={theme}  >
                {JSON.stringify(uploadingFileInfo, null, 2)}
              </SyntaxHighlighter>
            </div>

          </ModalBody>
          {console.log(tags)}
          {console.log(not(tags.length > 2))}
          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={handleDone} disabled={disabledCloseButton}>
              Done
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {
        isUploading && <Skeleton inline={false} style={{ width: '550px', height: 450 }} count={1} /> || (<Dashboard plugins={[DragDrop]} uppy={uppy} {...props} />)
      }


      {/* Loading indicator */}
      {isUploading && <div className="loading-spinner">Uploading...</div>}
    </div>
  );
};

function AlertDialogPopUp({ setOpenConfirmModal, closeModal }) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = React.useRef()

  useEffect(() => {
    onOpen()
  }, [])

  const handleCancel = () => {
    setOpenConfirmModal(false);
    onClose();
  }

  const handleClose = () => {
    closeModal();
    onClose();
    setOpenConfirmModal(false);
    window.location.reload();
  }

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Want's to close.?
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={handleCancel}>
                Cancel
              </Button>
              <Button colorScheme='red' onClick={handleClose} ml={3}>
                Close
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
